import { ChangeEvent, useEffect, useState } from "react";
import RootStore from "../store/Root";
import axios from "axios";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from 'react-phone-number-input'

interface UserInformationFormProps {
  rootStore: RootStore
}
function UserInformationForm({ rootStore }: UserInformationFormProps) {

  const { isFormCompleted, isSelectionCompleted, setIsFormCompleted, full_name, mobile_number, civil_id_number } = rootStore
  const navigate = useNavigate();
  const [checksCompleted, setChecksCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false)

  const [formError, setFormError] = useState({
    full_name: false,
    mobile_number: false,
    civil_id_no: false,
    valid_number: false,
    valid_civil_id: false
  })

  const validationCheck = (field: string) => {
    return (field.trim() !== '' && field !== undefined) ? false : true
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'fullName':
        rootStore.setFullName(event.target.value);
        setFormError({ ...formError, full_name: validationCheck(event.target.value) })
        break;
      case 'civilIdNumber':
        rootStore.setCivilIdNumber(event.target.value);
        let validCivilId = formError.valid_civil_id
        if (rootStore.civil_id_number.length === 12) {
          validCivilId = false
        }
        setFormError({ ...formError, civil_id_no: validationCheck(event.target.value), valid_civil_id: validCivilId })
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const newErrors = {
      ...formError,
    }

    if (validationCheck(full_name)) {
      newErrors.full_name = true
    }
    if (validationCheck(mobile_number)) {
      newErrors.mobile_number = true
    }
    if (validationCheck(civil_id_number)) {
      newErrors.civil_id_no = true
    }
    if (!isValidPhoneNumber(rootStore.mobile_number)) {
      newErrors.valid_number = true
    }
    if (rootStore.civil_id_number.length !== 12) {
      newErrors.valid_civil_id = true
    }
    setFormError(newErrors)

    if (newErrors.full_name || newErrors.mobile_number || newErrors.civil_id_no || newErrors.valid_number || newErrors.valid_civil_id) {
      return
    }

    const requestData = {
      full_name: rootStore.full_name,
      mobile_no: rootStore.mobile_number,
      civil_id_no: rootStore.civil_id_number,
      round_of_16: rootStore.round_16,
      quarter_final: rootStore.quarter_final,
      semi_final: rootStore.semi_final,
      final: rootStore.final,
      winner: rootStore.winner
    }
    
    setSubmitting(true)

    await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/match/predict/submit`, requestData).then((response) => {
      if (response.data.status === 1) {
        setIsFormCompleted()
        navigate('/goodluck')
      }
    }).catch(error => {
      if (error.response.status === 400) {
        console.log(error.response.data.message)
      }
    }).finally(() => setSubmitting(false))
  }

  const handleMobileNoChange = (data: string) => {
    if(data) {
      rootStore.setMobileNumber(data);
      setFormError({ ...formError, mobile_number: validationCheck(data), valid_number: !isValidPhoneNumber(data) })
    }
  }

  useEffect(() => {
    if (!isSelectionCompleted) {
      navigate('/');
    } else if (isFormCompleted) {
      navigate('/goodluck')
    } else {
      setChecksCompleted(true);
    }
  }, [isFormCompleted, isSelectionCompleted, navigate]);


  if (!checksCompleted) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit as any} className="form">
        <img src="/images/toyota-logo.png" alt="toyota logo" />
      <div className="form-wrapper">
        <div className="form-item">
          <span>FULL NAME</span>
          <input type="text" id="fullName" value={full_name} placeholder="Enter Full Name" onChange={handleChange} />
          {formError.full_name && <p>Please enter your full name</p>}
        </div>
        <div className="form-item">
          <span>MOBILE NUMBER</span>
          <div className="mobile-number">
            <PhoneInput
              countries={['KW']}
              defaultCountry="KW"
              addInternationalOption={false}
              placeholder="Enter Phone Number"
              value={mobile_number}
              onChange={(data) => handleMobileNoChange(data as string)} />
          </div>
          {formError.mobile_number && <p>Please enter your mobile number</p>}
          {formError.valid_number && !formError.mobile_number&& <p>Please enter valid mobile number</p>}
        </div>
        <div className="form-item">
          <span>CIVIL ID NUMBER</span>
          <input type="text" id="civilIdNumber" value={civil_id_number} placeholder="Enter Civil ID Number" onChange={handleChange} />
          {formError.civil_id_no && <p>Please Enter Your civil id number</p>}
          {formError.valid_civil_id && !formError.civil_id_no && <p>Please Enter valid civil id Number</p>}
        </div>
        <div className="bottom-btns">
          <button className="submit-btn" type="submit" disabled={submitting}>{submitting ? 'Submitting...' : 'Submit'}</button>
        </div>
      </div>
    </form>
  )
}

export default UserInformationForm;