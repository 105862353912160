import { makeObservable, observable, action } from "mobx"

class RootStore {
    public all_teams: string[]
    public round_16: string[]
    public quarter_final: string[]
    public semi_final: string[]
    public final: string[]
    public winner: string[]
    public full_name = ''
    public mobile_number = ''
    public civil_id_number = ''
    public error_message = ''
    public isSelectionCompleted: boolean
    public isFormCompleted: boolean

    constructor() {
        makeObservable(this, {
            all_teams: observable,
            round_16: observable,
            quarter_final: observable,
            semi_final: observable,
            final: observable,
            winner: observable,
            isSelectionCompleted: observable,
            isFormCompleted: observable,
            addRemoveRound16: action,
            addRemoveQF: action,
            addRemoveSF: action,
            addRemoveF: action,
            addRemoveW: action,
            full_name: observable,
            mobile_number: observable,
            civil_id_number: observable,
            error_message: observable,
            setFullName: action,
            setMobileNumber: action,
            setCivilIdNumber: action,
            setIsSelectionCompleted: action,
            setIsFormCompleted: action
        })
        this.all_teams = ['GER', 'SCO', 'HUN', 'SUI', 'ESP', 'CRO', 'ITA', 'ALB', 'SLO', 'DEN', 'SRB', 'ENG', 'POL', 'NED', 'AUT', 'FRA', 'BEL', 'SVK', 'ROU', 'UKR', 'TUR', 'GEO', 'POR', 'CZE']
        this.round_16 = []
        this.quarter_final = []
        this.semi_final = []
        this.final = []
        this.winner = []
        this.isSelectionCompleted = false
        this.isFormCompleted = false
    }

    addRemoveRound16 = (team: string, action: string) => {
        if (action === 'add') {
            this.round_16.push(team)
        }
        else {
            this.round_16 = this.round_16.filter((country: string) => country !== team)
            this.addRemoveQF(team, 'remove')
        }
    }

    addRemoveQF = (team: string, action: string) => {
        if (action === 'add') {
            if (this.isPresentInR16(team)) {
                this.quarter_final.push(team)
            }
            else {
                return
            }
        }
        else {
            this.addRemoveSF(team, 'remove')
            this.quarter_final = this.quarter_final.filter((country: string) => country !== team)
        }
    }

    addRemoveSF = (team: string, action: string) => {
        if (action === 'add') {
            if (this.isPresentInR16(team) && this.isPresentInQF(team)) {
                this.semi_final.push(team)
            }
            else {
                return
            }
        }
        else {
            this.semi_final = this.semi_final.filter((country: string) => country !== team)
            this.addRemoveF(team, 'remove')
        }
    }

    addRemoveF = (team: string, action: string) => {
        if (action === 'add') {
            if (this.isPresentInR16(team) && this.isPresentInQF(team) && this.isPresentInSf(team)) {
                this.final.push(team)
            }
            else {
                return
            }
        }
        else {
            this.final = this.final.filter((country: string) => country !== team)
            this.addRemoveW(team, 'remove')
        }
    }

    addRemoveW = (team: string, action: string) => {
        if (action === 'add') {
            if (this.isPresentInR16(team) && this.isPresentInQF(team) && this.isPresentInSf(team) && this.isPresentInF(team)) {
                this.winner.push(team)
            }
            else {
                return
            }
        }
        else {
            this.winner = this.winner.filter((country: string) => country !== team)
        }
    }

    checkIfPresent = (teamName: string, array: string[]) => {
        return array.includes(teamName)
    }

    isPresentInR16 = (teamName: string) => {
        return this.round_16.includes(teamName)
    }

    isPresentInQF = (teamName: string) => {
        return this.quarter_final.includes(teamName)
    }

    isPresentInSf = (teamName: string) => {
        return this.semi_final.includes(teamName)
    }

    isPresentInF = (teamName: string) => {
        return this.final.includes(teamName)
    }

    isPresentInW = (teamName: string) => {
        return this.winner.includes(teamName)
    }

    shouldDisableQF = () => {
        if (this.round_16.length !== 16) {
            return true
        }
        else {
            return false
        }
    }

    shouldDisableSf = () => {
        if (this.quarter_final.length !== 8) {
            return true
        }
        else {
            return false
        }
    }

    shouldDisableF = () => {
        if (this.semi_final.length !== 4) {
            return true
        }
        else {
            return false
        }
    }

    shouldDisableW = () => {
        if (this.final.length !== 2) {
            return true
        }
        else {
            return false
        }
    }

    shouldDisableSubmit = () => {
        if (this.round_16.length !== 16 || this.quarter_final.length !== 8 || this.semi_final.length !== 4 || this.final.length !== 2 || this.winner.length !== 1) {
            return true
        }
        else {
            return false
        }
    }

    setFullName(fullName: string) {
        this.full_name = fullName;
    }

    setMobileNumber(mobileNumber: any) {
        this.mobile_number = mobileNumber;
    }

    setCivilIdNumber(civilIdNumber: string) {
        this.civil_id_number = civilIdNumber;
    }

    setIsSelectionCompleted = () => {
        this.isSelectionCompleted = true
    }

    setIsFormCompleted = () => {
        this.isFormCompleted = true
    }
}

export default RootStore