import { useEffect, useState } from "react"
import RootStore from "../store/Root"
import { useNavigate } from "react-router-dom"

interface GoodLuckProps {
  rootStore: RootStore
}

export const GoodLuck = ({ rootStore }: GoodLuckProps) => {

  const { isFormCompleted, isSelectionCompleted } = rootStore
  const navigate = useNavigate();
  const [checksCompleted, setChecksCompleted] = useState(false);

  useEffect(() => {
    if (!isSelectionCompleted) {
      navigate('/');
    } else if (!isFormCompleted) {
      navigate('/form');
    } else {
      setChecksCompleted(true);
    }
  }, [isFormCompleted, isSelectionCompleted, navigate]);


  if (!checksCompleted) {
    return null;
  }

  return (
    <div className="goodluck-wrapper">
      <h1>Good Luck</h1>
    </div>
  )
}
