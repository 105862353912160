import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { GoodLuck } from './components/GoodLuck';
import RootStore from './store/Root';
import UserInformationForm from './pages/UserInformationForm';

function App() {

  const rootStore = new RootStore()

  return (
    <BrowserRouter>
      <div className='page-main form-page'>
        <div className='main-wrapper'>
          <Routes>
            <Route path="/" element={<Home rootStore={rootStore} />} />
            <Route path="/form" element={<UserInformationForm rootStore={rootStore} />} />
            <Route path="/goodluck" element={<GoodLuck rootStore={rootStore} />} />
            <Route path='/*' element={<Navigate to={'/'} />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
