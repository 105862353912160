import { observer } from "mobx-react-lite";
import RootStore from "../store/Root";
import uuid4 from "uuid4"

interface CountryListProps {
  title: string,
  countries: string[],
  name: string,
  selectedCountries: string[],
  rootStore: RootStore,
  totalTeams: number,
  maxSelection: number,
  shouldDisable: () => boolean,
  isPresent: (team: string) => boolean,
  onChange: (team: string, action: string) => void
}

function CountryList({ title, countries, name, selectedCountries, shouldDisable, isPresent, maxSelection, totalTeams, onChange }: CountryListProps) {

  const unselectedCounts = totalTeams - countries.length
  const emptyArray = new Array(unselectedCounts).fill('');

  const handleChange = (country: string) => {
    let action: string = 'add'
    if (selectedCountries && selectedCountries.includes(country)) {
      action = 'remove';
    }
    if(action === 'add' && selectedCountries?.length >= maxSelection){
      return
    }
    onChange(country, action);
  }

  return (
    <div className='country-list-wrapper'>
      <div className="title">
        {name === 'winner' && <img src="/images/design.png" alt="" />}
        <div className="font-h2">{title}</div>
      </div>
      <form className="checkbox-wrapper">
        {name === 'winner' && <div key={uuid4()} className="check-item"></div>}
        {countries.map((country) => (
          <div key={uuid4()} className="check-item">
            <input type="checkbox" id={`${country}_${name}`} disabled={(selectedCountries.length >= maxSelection && !selectedCountries.includes(country)) || shouldDisable()} checked={isPresent(country)} onChange={() => handleChange(country)} />
            <label htmlFor={`${country}_${name}`}><img src={`/images/country-icons/${country.toLowerCase()}.png`} alt={`${country} flag`} /></label>
          </div>
        ))}
        {
          unselectedCounts > 0 && emptyArray.map(() => (<div key={uuid4()} className="item">+</div>))
        }
      </form>
    </div>
  );
}
export default observer(CountryList);